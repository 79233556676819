@font-face {
  font-family: "OdibeeSans";
  src: local("OdibeeSans"),
    url("../assets/fonts/OdibeeSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "NotoSerifTC";
  src: local("NotoSerifTC"),
    url("../assets/fonts/NotoSerifTC-Regular.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "NotoSerif";
  src: local("NotoSerif"),
    url("../assets/fonts/NotoSerifTC.otf") format("truetype");
  font-weight: normal;
}

* {
  text-rendering: optimizeLegibility;
  // box-shadow: none !important;
}
#root {
}
body {
  margin: 0;
  padding: 0;
  font-family: "NotoSerif" !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
.analyticsIcon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  object-fit: contain;
  margin-bottom: 10px;
}

.line {
  vertical-align: middle;
  object-fit: contain;
  text-align: center;
  width: 100%;
  height: 20px;
  margin: 30px auto;
}
.field {
  margin: 50px auto;
}

.icon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.smIcon {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.lgIcon {
  width: 80px;
  height: 80px;
  vertical-align: middle;
  padding-right: 10px;
  object-fit: contain;
}
.block {
  margin-top: 60px;
}
.header {
  text-align: center;
  font-size: 40px;
  padding-top: 25px;
  font-weight: 700;
}
.card {
  background-color: rgb(50, 50, 50);
  color: white;
  text-align: center;
  overflow: hidden;
  border-radius: 40px;
  margin: 10px;
  padding: 30px;
}

.valueCard {
  background: linear-gradient(transparent, #f53838);
  box-shadow: 5px 13px 12px 4px rgba(225, 0, 0, 0.4);
  border: 1.5px solid #f53838;
  color: white;
  text-align: center;
  font-size: 22px;
  overflow: hidden;
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
  min-height: 350px;
}

.projectCard {
  color: white;
  overflow: hidden;
  text-align: left;
  height: 200px;
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
}
.alertCard {
  background-color: rgba(255, 255, 255, 0.3);
  color: black;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
}
.cardHeader {
  color: #e4cc8c;
  font-family: "NotoSerifTC";
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0;
  font-weight: 900;
  margin-top: 15px;
  padding-bottom: 30px;
}
.cardContent {
  color: #e4cc8c;
  font-size: 14px;
  line-height: 1.3;
}
.cardColorContent {
  font-family: "NotoSerif";
  color: white;
  font-size: 18px;
  line-height: 1.3;
}
.cardLgValue {
  color: #e4cc8c;
  font-size: 36px;
  padding-bottom: 25px;
  font-weight: 700;
}
.tab {
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}
.lightTab {
  background-color: rgba(225, 225, 225, 0.3);
  overflow: hidden;
  border-radius: 10px;
  margin: "0 10px";
  vertical-align: bottom;
  padding: 20px 20px;
}
.title {
  font-size: 55px;
  letter-spacing: 3px;
  vertical-align: middle;
  text-align: center;
  font-weight: 800;
  line-height: 1.3;
  margin: 50px 0;
  text-shadow: 1px 1px 6px #e4cc8cbf;
}
.subTitle {
  line-height: 1.2;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 1px 6px #e4cc8cbf;
}
.coloredTitle {
  color: white;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}
.coloredText {
  color: white !important;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboardHeader {
  font-size: 30px;
  vertical-align: middle;
  font-weight: 700;
}
.listHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.listContent {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
}
.valueHeader {
  font-size: 16px;
  line-height: 1.5;
  color: white;
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 15px;
}
.value {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: white;
}
.price {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.rightValue {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: right;
}

.tooltip {
  padding: 0;
  margin-left: 10px;
  margin-top: -2px;
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.labelLink {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.cardTitleItem {
  width: 20%;
}
.w20 {
  width: 20%;
}
.w15 {
  width: 15%;
}
.w10 {
  width: 10%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}

.rightText {
  float: right;
  font-weight: 600;
  margin-top: 20px;
}

.speedo-segment {
  margin: 10px;
  padding: 10px;
}

.InfiniteCarouselArrowIcon {
  border-color: black;
}
.MuiSelect-icon {
  color: white;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .title {
    font-size: 30px;
    letter-spacing: 2px;
    vertical-align: middle;
    font-weight: 800;
    line-height: 1.2;
    margin: 30px 0;
    text-align: center;
  }
}
